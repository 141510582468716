import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * The name of the cookie for storing the preference for necessary cookies
 */
export const LITHIUM_NECESSARY = 'LithiumNecessaryCookiesAccepted';
/**
 * The array of cookie names for setting the GDPR cookie preferences
 */
export const LITHIUM_COOKIES: string[] = [
  'LithiumNecessaryCookiesAccepted',
  'LithiumPerformanceCookiesAccepted',
  'LithiumTargetingCookiesAccepted',
  'LithiumFunctionalCookiesAccepted'
];

export const LithiumUserExternalVideoConsent = 'LithiumUserExternalVideoConsent';

/**
 * Class for objects used for storing and retrieving
 * the values of the GDPR cookie preferences
 */
export class GdprCookiePreferences {
  LithiumNecessaryCookiesAccepted: GdprCookieValues;

  LithiumPerformanceCookiesAccepted: GdprCookieValues;

  LithiumTargetingCookiesAccepted: GdprCookieValues;

  LithiumFunctionalCookiesAccepted: GdprCookieValues;
}

/**
 * Class for indicating whether user has already made his/her preferences
 * and for returning those preferences
 */
export class GdprCookies {
  userPreferenceExist: boolean;

  preferences: GdprCookiePreferences;
}

/**
 * enum with the possible values for the GDPR cookies
 * UNSELECTED - user has not made any preferences yet
 * ACCEPTED - user has accepted for us drop this category of cookies
 * REJECTED - user has not accepted for us to drop this category of cookies
 */
export enum GdprCookieValues {
  UNSELECTED,
  ACCEPTED,
  REJECTED
}

/**
 *
 * @returns GdprCookies object that gives the values of the user preference
 * gdpr cookies and whether the user has made any preferences or not
 */
export function fetchGdprCookies(): GdprCookies {
  const gdprCookies: GdprCookies = new GdprCookies();
  gdprCookies.preferences = new GdprCookiePreferences();
  let userPreferenceExist = false;
  LITHIUM_COOKIES.forEach(cookieKey => {
    const cookie = cookies.get(cookieKey);
    if (cookie && cookie !== GdprCookieValues.UNSELECTED.toString()) {
      gdprCookies.preferences[cookieKey] = Number.parseInt(cookie);
      userPreferenceExist = true; //indicate cookies exist if at least one cookie is present
    }
  });
  gdprCookies.userPreferenceExist = userPreferenceExist;
  if (!userPreferenceExist) {
    LITHIUM_COOKIES.forEach(cookieKey => {
      gdprCookies.preferences[cookieKey] = GdprCookieValues.UNSELECTED;
    });
  }
  return gdprCookies;
}

/**
 * Function to store the user's gdpr preferences in their respective cookies
 *
 * @param cookiePrefs the user's cookie preferences
 */
export function setGdprCookies(cookiePrefs: GdprCookiePreferences, cookieMaxAge?: number): void {
  LITHIUM_COOKIES.forEach(cookieKey => {
    cookies.set(cookieKey, cookiePrefs[cookieKey].toString(), {
      maxAge: cookieMaxAge
    });
  });
}

/**
 * Retrieves the value of the 'LithiumUserExternalVideoConsent' cookie
 */
export function getExternalVideoConsentCookie(): boolean {
  return cookies.get(LithiumUserExternalVideoConsent) === GdprCookieValues.ACCEPTED;
}

/**
 * Sets the external video consent cookie.
 */
export function setExternalVideoConsentCookie(): void {
  cookies.set(LithiumUserExternalVideoConsent, GdprCookieValues.ACCEPTED);
}
