import type { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import type { User } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ItemType, UserViewVariant } from '../../types/enums';
import type { ItemListCommonProps } from '../common/List/ItemList';
import type { ComponentCommonProps, WidgetProps } from '../common/Widget/types';
import type { ItemViewTypeAndProps } from '../entities/types';

export type UserList = ItemListCommonProps<
  User,
  ItemViewTypeAndProps<ItemType.USER, UserViewVariant>
>;

export interface UserListWidget extends ComponentCommonProps, WidgetProps, UserList {
  /**
   * Whether to show title or not
   */
  useTitle?: boolean;

  /**
   * Type of panel to use for widget
   */
  panelType?: PanelType;

  /**
   * Whether to show footer or not
   */
  useFooter?: boolean;
}

/**
 * The user group(s) a widget is visible to.
 */
export enum WidgetVisibilityGroup {
  /**
   * Only signed-in users can view the widget.
   */
  SIGNED_IN_ONLY = 'signedInOnly',
  /**
   * Only anonymous (not signed in) users can view the widget.
   */
  ANONYMOUS_ONLY = 'anonymousOnly',
  /**
   * Both anonymous and signed-in users can view the widget.
   */
  SIGNED_IN_OR_ANONYMOUS = 'signedInOrAnonymous'
}
