import WidgetContext from '@aurora/shared-client/components/context/WidgetContext';
import SectionWidgetContext from '@aurora/shared-client/components/context/SectionWidgetContext';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import endUserComponentRegistry from '../../../features/endUserComponentRegistry';
import { WidgetEditLevel } from '../../../helpers/quilt/PageEditorHelper';
import EditContext from '../../context/EditContext/EditContext';
import PageEditorQuiltWrapperContext from '../../context/PageEditorQuiltWrapperContext/PageEditorQuiltWrapperContext';
import type WidgetChildren from '../WidgetChildren/WidgetChildren';
import type { WidgetProps } from './types';

export interface WidgetComponentProps<WidgetPropsT extends WidgetProps> {
  /**
   * Content that should render as a widget.
   */
  children: WidgetChildren;

  /**
   * final props of the widget
   */
  props: WidgetPropsT;
}

const WidgetEditor = dynamic(() => import('../WidgetEditor/WidgetEditor'));
const WrapperWidgetEditor = dynamic(() => import('../WrapperWidgetEditor/WrapperWidgetEditor'));
const QuiltWrapperSectionWidgetEditorActionMenu = dynamic(
  () =>
    import(
      '../../pageeditor/QuiltWrapperSectionWidgetEditorActionMenu/QuiltWrapperSectionWidgetEditorActionMenu'
    )
);

interface Props<WidgetPropsT extends WidgetProps> extends WidgetComponentProps<WidgetPropsT> {
  /**
   * Class name(s) to apply to the EditControlSet
   */
  className?: string;
}

/**
 * Wrapper to use for widgets that are editable. This should not add any additional markup
 * to the widget.
 *
 * @author Manish Shrestha
 */
const EditableWidget = <WidgetPropsT extends WidgetProps>({
  children,
  props,
  className
}: Props<WidgetPropsT>): React.ReactElement => {
  const { id } = useContext(WidgetContext);
  const { showEditControls: showPageEditControls } = useContext(EditContext);
  const { showEditControls: showQuiltWrapperEditControls } = useContext(
    PageEditorQuiltWrapperContext
  );
  const { location } = useContext(SectionWidgetContext);
  const { editLevel = WidgetEditLevel.FULL } = props || {};
  const isWrapperLocation =
    !!endUserComponentRegistry.getWidgetDescriptor(id)?.widgetLocation &&
    endUserComponentRegistry.getWidgetDescriptor(id)?.widgetLocation === 'wrapper';

  // if a widget is rendered in the header or footer section then editing is not yet supported.
  if (
    location &&
    showPageEditControls &&
    editLevel !== WidgetEditLevel.LOCKED &&
    !isWrapperLocation
  ) {
    return <WidgetEditor props={props}>{children}</WidgetEditor>;
  } else if (showPageEditControls && editLevel !== WidgetEditLevel.LOCKED && isWrapperLocation) {
    return <WrapperWidgetEditor props={props}>{children}</WrapperWidgetEditor>;
  } else if (showQuiltWrapperEditControls && editLevel !== WidgetEditLevel.LOCKED) {
    return (
      <QuiltWrapperSectionWidgetEditorActionMenu props={props} className={className}>
        {children}
      </QuiltWrapperSectionWidgetEditorActionMenu>
    );
  } else {
    return <>{children}</>;
  }
};

export default EditableWidget;
